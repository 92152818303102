@font-face {
  font-family: "SF Pro";
  src: url("/fonts/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Light";
  src: url("/fonts/SF-Pro-Display-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

/* Add more @font-face rules for other styles if needed */
